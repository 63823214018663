.cm-pfh-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9000;
    background: #FFFFFF;
    border-top: solid 5px #259BDB;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, .15);
    transition: all .8s;
    border-radius: 0;
}

.panel-body {
    padding-left: 0px;
    padding-right: 0px;

}

.cm-inline > input,
.cm-inline > select,
.cm-inline > .input-group {
    margin-left: 20px;
}

.cm-inline > input:first-child {
    margin-left: 0;
}

.panel-primary {
    border-color: #259BDB;
}

.panel-primary > .panel-heading {
    background: #259BDB;
}

.table > tbody > tr > td > div.status {
    padding: 5px;
    border-radius: 5px;
    text-align: center;
}

.table > tbody > tr > td > div.cancelled {
    background-color: #FF786D !important;
    color: #FFFFFF;
}

.table > tbody > tr > td > div.expired {
    background-color: #999999 !important;
    color: #FFFFFF;
}

.table > tbody > tr > td > div.refunded {
    background-color: #FFFF00 !important;
    color: #333333;
}

.table > tbody > tr > td > div.refundpending {
    background-color: #323FFF !important;
    color: #FFFFFF;
}

.table > tbody > tr > td > div.refundfailed {
    background-color: #FF082A !important;
    color: #FFFFFF;
}

.table > tbody > tr > td > span > div.splitrefund {
    width: 100%;
}

.table > tbody > tr > td > div.accepted {
    background-color: #FFB15A !important;
    color: #333333;
}

.table > tbody > tr > td > div.failure {
    background-color: #FF6633 !important;
    color: #FFFFFF;
}

.table > tbody > tr > td > div.success {
    background-color: #19B34D !important;
    color: #FFFFFF;
}

.table > tbody > tr > td > div.reversed {
    background-color: #FFA55A !important;
    color: #FFFFFF;
}

.table > tbody > tr > td > div.open {
    background-color: #F4F4F4 !important;
}

.table > tbody > tr > td > div.test {
    background-color: #5BC0DE !important;
    color: #FFFFFF;
}

.table > tbody > tr > td > div > a > span.action {
    padding-top: 5px;
    padding: 5px;
    border-radius: 5px;
}

.table > tbody > tr > td > div > a.refund {
    background-color: #6B7676;
    color: #FFFFFF;
}

.table > tbody > tr > td > div > a.finalize {
    background-color: #3F3637;
    color: #FFFFFF;
}

.statusCol {
    min-width: 100px;
    width: 100px !important;
    max-width: 100px;
}

ul.dropdown-menu > li > a {
    color: #000000;
    padding-left: 0;

}

.dropdown-link {
    padding-top: 32px !important;
    line-height: 17px !important;
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a {
    padding-left: 25px;
}

/*Form Wizard*/
.bs-wizard {
    border-bottom: solid 1px #E0E0E0;
    padding: 0 0 10px 0;
}

.bs-wizard > .bs-wizard-step {
    padding: 0;
    position: relative;
}

.bs-wizard > .bs-wizard-step + .bs-wizard-step {
}

.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
    color: #595959;
    font-size: 16px;
    margin-bottom: 5px;
}

.bs-wizard > .bs-wizard-step .bs-wizard-info {
    color: #999999;
    font-size: 14px;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
    position: absolute;
    width: 30px;
    height: 30px;
    display: block;
    background: #259BDB;
    top: 45px;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
    content: ' ';
    width: 14px;
    height: 14px;
    background: #FFFFFF;
    border-radius: 50px;
    position: absolute;
    top: 8px;
    left: 8px;
}

.bs-wizard > .bs-wizard-step > .progress {
    position: relative;
    border-radius: 0px;
    height: 8px;
    box-shadow: none;
    margin: 20px 0;
}

.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
    width: 0px;
    box-shadow: none;
    background: #259BDB;
    opacity: 0.75;
}

.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
    width: 100%;
}

.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
    width: 50%;
}

.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
    width: 0%;
}

.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
    width: 100%;
}

.bs-wizard > .bs-wizard-step.active > .bs-wizard-dot {
    background-color: #259BDB;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
    background-color: #F5F5F5;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {
    opacity: 0;
}

.bs-wizard > .bs-wizard-step:first-child > .progress {
    left: 50%;
    width: 50%;
}

.bs-wizard > .bs-wizard-step:last-child > .progress {
    width: 50%;
}

.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot {
    pointer-events: none;
}

/*END Form Wizard*/

.caret {
    position: static !important;
}

.dt-buttons {
    margin-right: 20px;
}

#charges_length {
    width: auto;
    float: left;
    position: relative;
}

.td-align {
    width: 50%;
}

.modal-header {
    background-color: #259BDB;
    color: #FFFFFF;
}

.modal-title {
    font-weight: bolder;
}

#charges_processing {
    background-color: #FFFFFF;
    padding: 0;
    border: 2px solid #000000 !important;
    margin-top: -110px;
    opacity: .8;
    left: 50%;
    top: 50%;
    height: 220px;
    font-size: 24px;
    border-radius: 25px !important;
    position: fixed;
    z-index: 9999;
}

.risccolor_white {
    background-color: #FFFFFF;
    color: #000000;
}

.risccolor_black {
    background-color: #000000;
    color: #FFFFFF;
}

.navbar-brand {
    padding: 3px 15px;
    position: relative;
}

.navbar-brand > img {
    height: 44px;
    width: auto;
}

.navbar-header {
    height: 50px;
}

.navbar-toggle {
    margin-top: 4px;
}

.navbar {
    min-height: 50px;
}

.navbar-brand span {
    position: absolute;
    top: 50%;
    left: 80px;
    -ms-transform: translateY(-50%); /* IE 9 */
    -webkit-transform: translateY(-50%); /* Safari */
    transform: translateY(-50%);
}

.extra-margin {
    margin-top: 7rem;
    margin-bottom: 4rem;
}

.error-text-bold {
    font-weight: bold;
    color: #a94442;
}

.header-danger {
    background-color: #a94442 !important;
    color: white;
}

.header-warning {
    background-color: #d29d00 !important;
    color: white;
}

.show {
    display: block;
}

.hide {
    display: none;
}

@media only screen and (min-width: 768px) {
    .navbar-brand {
        padding-top: 8px;
        padding-bottom: 8px;
        height: 65px;
    }
    .navbar-header {
        height: 65px;
    }
    .navbar-brand > img {
        padding-top: 0;
        height: 50px;
        width: auto;
    }
    .navbar-nav>li>a {
        padding-top: 0;
        padding-bottom: 0;
        line-height:66px;
    }
}

@media only screen and (min-width: 991px) {
    .navbar-default .navbar-nav>li>a {
        line-height: 65px;
    }

}

@media only screen and (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}
