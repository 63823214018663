.glyphicon {
    margin-right: 5px;
}

.thumbnail {
    margin-bottom: 20px;
    padding: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}

.item.list-group-item {
    float: none;
    width: 100%;
    background-color: #FFFFFF;
    margin: 15px;
}

.item.list-group-item:nth-of-type(odd):hover, .item.list-group-item:hover {
    background: #428BCA;
}

.item.list-group-item .list-group-image {
    margin-right: 10px;
}

.item.list-group-item .thumbnail {
    margin-bottom: 0px;
}

.item.list-group-item .caption {
    padding: 9px 9px 0px 9px;
}

.item.list-group-item:nth-of-type(odd) {
    background: #EEEEEE;
}

.item.list-group-item:before, .item.list-group-item:after {
    display: table;
    content: " ";
}

.item.list-group-item img {
    float: left;
}

.item.list-group-item:after {
    clear: both;
}

.list-group-item-text {
    margin: 0 0 11px;
}
